import React, { Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import {
  ButtonToolbar,
  ButtonGroup,
  Button,
  MenuItem,
  Dropdown,
  Modal,
  FormControl,
} from 'react-bootstrap';

import { FieldGroup, BatchActionsToolbar, csrfToken } from 'pm_adminus';

const defaultExportFormat = 'JSON'

class MissionsBatchActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsAvailable: false,
      showImportModal: false,
      selectedIds: [],
      selectedExportFormat: defaultExportFormat
    };

    this.toolbar = createRef();
  }

  selectionChanged = (itemsMap) => {
    this.setState({
      itemsAvailable: itemsMap.size > 0,
      selectedIds: [...itemsMap.keys()],
    });
  }

  exportItems = () => {
    const { exportUrl } = this.props;
    const { selectedIds, selectedExportFormat } = this.state;

    const exportPath = `${exportUrl}?export_ids=${selectedIds.toString()}&export_format=${selectedExportFormat}`;

    this.toolbar.current.handleResetSelection();

    window.location = exportPath;
  }

  handleImportModalShow = () => {
    this.setState({ showImportModal: true });
  }

  handleImportModalClose = () => {
    this.setState({ showImportModal: false });
  }

  handleExportFormatSelect = (e) => {
    this.setState({ selectedExportFormat: e.target.text }, () => this.exportItems() );
  }

  renderApplicationOptions() {
    const { applications } = this.props;

    return applications.map(item => <option key={`app${item[0]}`} value={item[0]}>{item[1]}</option>);
  }

  renderImportModal() {
    const { importUrl, importTitle } = this.props;
    const { showImportModal } = this.state;

    return (
      <Modal show={showImportModal} onHide={this.handleImportModalClose}>
        <form action={importUrl} method="POST" encType="multipart/form-data">
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <Modal.Header closeButton>
            <Modal.Title>{importTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FieldGroup
              id="application_id"
              name="application_id"
              label="Application"
              help="Select application in case you are importing Symbol objectives"
            >
              <FormControl
                componentClass="select"
                name="application_id"
              >
                {this.renderApplicationOptions()}
              </FormControl>
            </FieldGroup>
            <FieldGroup
              id="import_file"
              type="file"
              name="import_file"
              label="File"
              help="Select valid JSON or CSV file for import"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" bsStyle="success">Import</Button>
            <Button onClick={this.handleImportModalClose}>Close</Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  renderExportButton(){
    const { exportFormats } = this.props;
    const { itemsAvailable } = this.state;

    if (exportFormats.length > 1) {
      const exportFormatsElements = exportFormats.map((exportFormat) =>
        <MenuItem eventKey={exportFormat} key={exportFormat} onClick={this.handleExportFormatSelect}>
          {exportFormat}
        </MenuItem>
      );
      return (
        <Dropdown disabled={!itemsAvailable}  id="export-type-selector">
          <Dropdown.Toggle bsStyle="warning">
            Export
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {exportFormatsElements}
          </Dropdown.Menu>
        </Dropdown>  
      );
    }
    return (
      <Button bsStyle="warning" disabled={!itemsAvailable} onClick={this.exportItems}>
        Export
      </Button>
    );
  }

  render() {
    const { table, storageKey } = this.props;

    return (
      <>
        <ButtonToolbar>
          <ButtonGroup>
            <Button bsStyle="info" onClick={this.handleImportModalShow}>
              Import
            </Button>
            &nbsp;
          </ButtonGroup>
          <BatchActionsToolbar
            ref={this.toolbar}
            selector={table}
            storageKey={storageKey}
            onChange={this.selectionChanged}
          >
            {this.renderExportButton()}
          </BatchActionsToolbar>
        </ButtonToolbar>
        {this.renderImportModal()}
      </>
    );
  }
}

MissionsBatchActions.propTypes = {
  table: PropTypes.string.isRequired,
  importTitle: PropTypes.string,
  applications: PropTypes.arrayOf(PropTypes.array),
  storageKey: PropTypes.string,
  importUrl: PropTypes.string.isRequired,
  exportUrl: PropTypes.string.isRequired,
  exportFormats: PropTypes.arrayOf(PropTypes.string),
};

MissionsBatchActions.defaultProps = {
  applications: [],
  importTitle: 'Import Missions',
  storageKey: 'mission_templates_batch',
  exportFormats: [defaultExportFormat]
};

export default MissionsBatchActions;
